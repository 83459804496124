import type { ExtensionConfig } from '@goto/shell-common'

import meeting from './meeting'
import meetings from './meetings'
import meetingMeetNow from './meeting-meet-now'
import meetingBackgroundService from './meeting-background-service'
import voice from './voice'
import trainings from './trainings'
import webinars from './webinars'
import messaging from './messaging'
import analytics from './analytics'
import contactCenter from './contact-center'
import contactCenterAgent from './contact-center-agent'
import contactCenterConfig from './contact-center-config'
import contactCenterSupervisor from './contact-center-supervisor'
import contactCenterWallboard from './contact-center-wallboard'
import contactCenterResolve from './contact-center-resolve'
import contactCenterSurveys from './contact-center-surveys'
import contactCenterTopicFlag from './contact-center-topic-flag'
import contactCenterQualityManagement from './contact-center-quality-management'
import contactCenterQualityManagementConfig from './contact-center-quality-management-config'
import engagementConfigs from './engagements'
import contacts from './contacts'
import networkMeasurements from './network-measurements'
import webinarsV2 from './webinars-v2'
import teaser from './teaser'

const extensionConfigs: ExtensionConfig[] = [
  meeting,
  meetingMeetNow,
  meetingBackgroundService,
  meetings,
  voice.mainExtension,
  voice.softphoneUIExtension,
  trainings,
  webinars,
  webinarsV2,
  messaging,
  analytics,
  contactCenterConfig,
  contactCenter,
  contactCenterAgent,
  contactCenterSupervisor,
  contactCenterWallboard,
  contactCenterResolve,
  contactCenterSurveys,
  contacts,
  ...engagementConfigs,
  networkMeasurements,
  contactCenterTopicFlag,
  contactCenterQualityManagement,
  contactCenterQualityManagementConfig,
  voice.bugReporterExtension,
  teaser,
]

export default extensionConfigs
