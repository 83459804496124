import { PrerequisiteRuleTypes, type ExtensionConfig } from '@goto/shell-common'
import { getFeatureFlag } from '../helpers/shell-helpers'

enum Flags {
  GTC_VOICE_EXPERIENCE_VERSION = 'gtc-voice-experience-version-picker',
}

const canOverrideExperiencePath = (version: string) => version !== 'latest'

const getVoiceExperiencePath = () => {
  const version = getFeatureFlag(Flags.GTC_VOICE_EXPERIENCE_VERSION, 'latest')

  /**
   * Note: The `path` variable is associated with the Voice feature branch release process.
   * It will be replaced with a feature branch URL during deployment.
   *
   * If you modify the default path (`/experiences/voice/<default is latest>`),
   * ensure that the change is also reflected in the Voice feature branch deployment
   * process to maintain compatibility.
   *
   * For more details, refer to the Voice feature branch utility script:
   * {@link https://github.com/jive/goto-voice/blob/master/packages/app/scripts/feature-branch.utils.js#L9}
   */
  let path = '/experiences/voice/latest'

  if (canOverrideExperiencePath(version)) {
    path = `/experiences/voice/${version}`
  }

  return path
}

const prerequisiteEntitlements = 'jive || gotoconnect || grasshopper'

/**
 * Extension config
 */
const extensionConfig: ExtensionConfig = {
  id: '@voice',
  get url() {
    const path = getVoiceExperiencePath()
    return new URL(`${path}/extension.js`, window.location.origin)
  },
  dependencies: ['@voice-softphone-ui'],
  unguardedRoutes: [],
  esModule: true,
  prerequisites: {
    [PrerequisiteRuleTypes.ENTITLEMENTS]: prerequisiteEntitlements,
  },
  integrationConfig: {
    shouldLoadOnMobile: true,
    shouldLoadInIntegration: true,
    shouldLoadInCompanion: true,
    commandsToForwardToCompanion: [
      {
        namespace: 'Softphone',
        commandNames: ['showSoftphoneWidget'],
      },
      {
        namespace: 'VoiceIntegration',
        commandNames: [
          'reEmitLastCallsStateOnCompanion',
          'reEmitLastSettingsStateOnCompanion',
          'updateSettingsOnCompanion',
          'updateExtensionOnCompanion',
        ],
      },
    ],
  },
}

const softphoneUIExtensionConfig: ExtensionConfig = {
  id: '@voice-softphone-ui',
  get url() {
    const path = getVoiceExperiencePath()
    return new URL(`${path}/extension-softphone-ui.js`, window.location.origin)
  },
  // When the UI is loaded in the dedicated softphone window with the
  // standaloneRoutes declared below, only the extensions declared in the
  // dependencies list will be loaded. Even though the UI depends on the
  // call system running in the voice extension, we don't
  // declare the dependency here because it would reload it in the 2nd window.
  // Instead, the UI interacts with the call system running in any window using
  // shell commands and event bus.
  dependencies: [],
  optionalDependencies: ['@goto/contacts-ui-extension'],
  standaloneRoutes: ['^/softphone-ui'],
  unguardedRoutes: [],
  prerequisites: {
    [PrerequisiteRuleTypes.ENTITLEMENTS]: prerequisiteEntitlements,
  },
  esModule: true,
  integrationConfig: {
    shouldLoadInIntegration: true,
    shouldLoadInCompanion: true,
  },
}

const bugReporterExtensionConfig: ExtensionConfig = {
  id: '@shell-bug-reporter',
  get url() {
    const path = getVoiceExperiencePath()
    return new URL(`${path}/extension-bug-report.js`, window.location.origin)
  },
  dependencies: [],
  unguardedRoutes: [],
  esModule: true,
  integrationConfig: {
    shouldLoadInIntegration: true,
  },
}

export default {
  mainExtension: extensionConfig,
  softphoneUIExtension: softphoneUIExtensionConfig,
  bugReporterExtension: bugReporterExtensionConfig,
}
