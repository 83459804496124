import {
  NotificationChannelNamespace,
  NotificationChannelEvents,
} from '@goto/shell'
import { LaunchDarklyPayload } from '../models/feature-flag.models'
import { reloadPage } from './dom-helpers'

const RELOAD_APP_ERROR_PAGE_ON_RECONNECT_FLAG =
  'reload-app-error-page-on-reconnect'
const MAX_RELOAD_ATTEMPTS = 2
const MAX_RELOAD_PERIOD = 60000 * 60 // 1 hour

export const getLaunchDarklyFlag = <U extends keyof T, T = LaunchDarklyPayload>(
  flag: U
) => globalThis.shell.featureFlags?.getLaunchDarklyFlag<U, T>(flag)

export const getFeatureFlag = <T>(key: string, defaultValue: T): T =>
  globalThis.shell.featureFlags?.getFeatureFlagValue<T>(key, defaultValue) ??
  defaultValue

const canReloadApplication = () => {
  const reloadFeatureFlagEnabled = getFeatureFlag(
    RELOAD_APP_ERROR_PAGE_ON_RECONNECT_FLAG,
    false
  )
  if (!reloadFeatureFlagEnabled) {
    return false
  }

  const currentTime = new Date().getTime()
  const lastReloadData = JSON.parse(
    localStorage.getItem('goto-reload-attempts') ??
      JSON.stringify({ lastReloadTimestamp: 0, reloadAttemptCount: 0 })
  )

  let reloadAttemptCount = lastReloadData.reloadAttemptCount
  let lastReloadTimestamp = lastReloadData.lastReloadTimestamp

  if (currentTime - lastReloadData.lastReloadTimestamp >= MAX_RELOAD_PERIOD) {
    reloadAttemptCount = 0
    lastReloadTimestamp = currentTime
    localStorage.setItem(
      'goto-reload-attempts',
      JSON.stringify({ lastReloadTimestamp, reloadAttemptCount })
    )
  }

  if (lastReloadData.reloadAttemptCount >= MAX_RELOAD_ATTEMPTS) {
    return false
  }

  localStorage.setItem(
    'goto-reload-attempts',
    JSON.stringify({
      lastReloadTimestamp,
      reloadAttemptCount: reloadAttemptCount + 1,
    })
  )
  return true
}

export const attemptAppReloadWhenOnline = () => {
  const notificationChannelEvents = window.shell.eventBus.subscribeTo<
    typeof NotificationChannelNamespace,
    typeof NotificationChannelEvents
  >(NotificationChannelNamespace)

  const connectedHandler = () => {
    if (canReloadApplication()) {
      notificationChannelEvents.connected.removeListener(connectedHandler)
      reloadPage()
    }
  }

  notificationChannelEvents.connected.addListener(connectedHandler)

  window.addEventListener('unload', () => {
    notificationChannelEvents.connected.removeListener(connectedHandler)
  })
}
